<script context="module">
  // TODO: promiseResolver pass fn inputStr=>Promise, caches promises by inputStr and uses sequence logic from urlJsonResolver
  // import promiseResolver from './lib/promiseResolver.js'
  import fetchJsonFn from './Autocomplete/cachedFetchJsonFn'
  import urlJsonResolver from './Autocomplete/urlJsonResolver'
  import mapboxGeocodeResolver from './Autocomplete/mapboxGeocodeResolver'
  export { /* promiseResolver, */ urlJsonResolver, mapboxGeocodeResolver, fetchJsonFn }
</script>
<script>
// Autocomplete shows an autocomplete popup as the user types into an input.
// The required properties that must be set on it are resolver and onClick:
// 
//   resolver - function that should return a callback that takes a Svelte
//   store as an argument and returns a Svelte store that sets the list of
//   items to show in the autocomplete popup
//   onClick - callback that receives the value the user clicked on in the
//   autocomplete popup
//
// Example:
//   widget = new AutocompleteComponent({
//     target: document.getElementById('target'),
//     props: {
//       value: 'initial value',
//       id:'c2_address',
//       name:'address',
//       onClick:value=>{console.log(`Clicked "${value}"`)},
//       resolver:searchTextStore=>{
//         return AutocompleteComponent.derived(searchTextStore,($text,set)=>{
//           if(!$text) set([])
//           else set([{label: `${$text} One`, value: 'One'}, {label: `${$text} Two`, value: 'Two'}])
//         },[])
//       },
//       onIntent:(options,_index)=>console.log(`Do something in preparation for ${_index} being clicked`),
//     }
//   });
//
// Svelte store docs: https://svelte.dev/docs#svelte_store

  import { writable, derived } from './Autocomplete/store'
  import { onMount } from 'svelte';
    
  export let value;
  export let id;
  export let name;
  export let resolver;

  export let resultMap=v=>v
  export let onClick=v=>console.log("Default click",v)
  export let onIntent=null // (options,index)=>{ ... }
  export let editorAttrs={}
  export let suppress=false // turn off all behavior

  let visible = false
  let invisibleTimeout

  let resultElements = []
  let activeIndex = 0

  const key = e=>{
    if(suppress) return true;
    if("ArrowDown"==e.key){
      const newIndex = (activeIndex+1 <= $results.length ? activeIndex+1 : 0)
      resultElements[newIndex].focus()
      e.preventDefault()
    } else if("ArrowUp"==e.key){
      const newIndex = (activeIndex-1 >= 0 ? activeIndex-1 : $results.length)
      resultElements[newIndex].focus()
      e.preventDefault()
    } else if("Escape"==e.key) {
      resultElements[0].focus()
      visible = !visible
    } else if("Enter"==e.key) {
      if(0==activeIndex && $results.length > 0) resultElements[1].click()
    }
    return true;
  }

  const input = writable(value)
  // This is the only place that needs to react directly to value and suppress.
  // For other functions, it suffices to check suppress when they run.
  $: input.set(suppress ? '' : value)
  const active = writable(!value || 0==value.length)
  input.subscribe(v=>{ if(!suppress && (!v || v.length < 2)) active.set(true) })
  const activeInput = derived([active,input],([$active,$input])=>$active ? $input : null)
  const resolved = resolver(activeInput)
  const results = derived(resolved,$resolved=>$resolved.map(resultMap))

  const intentFn = index=>{
    if(!onIntent) return;
    return ()=>onIntent($results,index)
  }
  const blur = e=>{
    // console.log('blur',e)
    invisibleTimeout = setTimeout(()=>visible = false,200)
  }
  const focusFn = i=>e=>{
    // console.log('focus',e)
    clearTimeout(invisibleTimeout);
    if(i>0&&onIntent) intentFn(i-1)();
    activeIndex = i
    visible = true
  }
  const onClickFn = v=>_=>{
    active.set(false)
    onClick(v)
  }

  onMount(()=>{
    if(!onIntent) return;
    let intentTimeout
    return results.subscribe(options=>{
      clearTimeout(intentTimeout);
      if(options.length){
        intentTimeout = setTimeout(()=>onIntent(options,null),2000);
      }
    })
  })
</script>

<div style="position:relative;overflow:visible" role="group" on:keydown={key}>
  <input {...editorAttrs} {name} {id}
    class:form-control={true} 
    aria-owns={`${id}-result-group`} 
    bind:value
    bind:this={resultElements[0]}
    on:focus={focusFn(0)}
    on:blur={blur}
    autocomplete="off"
  />
  <div id={`${id}-result-group`} role="group" class="dropdown-menu" class:show={visible && $results.length}>
    {#each $results as {label,value}, i (label)}
      <a class="dropdown-item" tabindex="-1" href="#_"
        role="option"
        aria-selected={activeIndex == i+1}
        bind:this={resultElements[i+1]}
        on:click|preventDefault={onClickFn(value)}
        on:mouseover={intentFn(i)}
        on:focus={focusFn(i+1)}
        on:blur={blur}
      >{label}</a>
    {/each}
  </div>
</div>
