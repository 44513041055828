import onElementExists from "~/lib/onElementExists";
import algoliasearch from "algoliasearch/lite";
import aa from "search-insights";
import Autocomplete from "~/components/Autocomplete.svelte";
import { derived } from "svelte/store";

onElementExists("#search-help-container", dataEl => {
  const $ = window.jQuery
  const algoliaAppId = dataEl.dataset.algoliaAppId;
  const algoliaApiKey = dataEl.dataset.algoliaApiKey;
  const client = algoliasearch(algoliaAppId, algoliaApiKey);
  const index = client.initIndex('faq');
  const slideDuration = 300;
  const sessionStorageKey = 'faq-current-questions';

  aa('init', {appId: algoliaAppId, apiKey: algoliaApiKey, useCookie: true});

  // When they click to open help
  function openHelp(focus) {
    document.getElementById('search-help-container').classList.add('open');
    setTimeout(function() {
      if (focus) {document.getElementById('query').focus()}
      document.getElementById('open-help').style.position = 'fixed'; // remove it from the flow so we have more space on mobile with keyboard open
    }, slideDuration);
  }
  $('#open-help').click(function() {openHelp(true)});

  // When they click to close help
  $('#search-help .close').click(function() {
    currentlyViewedAnswers = [];
    sessionStorage.removeItem(sessionStorageKey);
    document.getElementById('search-help-container').classList.remove('left-open');
    document.getElementById('search-help-container').classList.remove('open');
    setTimeout(function() {
      document.getElementById('open-help').style.position = '';
      document.querySelector('#search-help .card-body').innerHTML = '';
    }, slideDuration);
  });

  // On submit (via Enter key), actually execute the search.
  $('#search-help-form').submit((e) => {
    e.preventDefault();
    if (enterFunction) enterFunction();
  });

  function markAnswerReacted(id) {
    currentlyViewedAnswers.forEach(value => value.reacted = value.reacted || value.id == id)
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(currentlyViewedAnswers));
  }

  // Render selected question/answers
  function renderCurrentAnswers() {
    const container = new DocumentFragment();
    currentlyViewedAnswers.forEach(value => {
      const itemContainer = document.createElement('div')
      const resultEl = $(`
        <div class="alert alert-primary">
          <strong>${value.question}</strong>
          <br/>
          ${value.answer}
        </div>
      `)[0];
      itemContainer.appendChild(resultEl);
      itemContainer.appendChild(
        $(`
          <div class="alert alert-primary" style="display: flex; gap: 10px; align-items: center;">
            ${value.reacted ? 'Thank you for your feedback!' : `Was this helpful?
            <button class="btn btn-sm btn-success helpfulJs"><i class="icon-thumbs-up-alt"></i> Yes</button>
            <button class="btn btn-sm btn-danger unhelpfulJs"><i class="icon-thumbs-down-alt"></i> No</button>`}
          </div>
        `).on('click .helpfulJs', () => aa('convertedObjectIDsAfterSearch', {
          index: 'faq',
          eventName: 'Helpful',
          objectIDs: [value.objectID],
          queryID: value.queryID,
        })).on('click .unhelpfulJs', () => aa('clickedObjectIDsAfterSearch', {
          index: 'faq',
          eventName: 'Unhelpful',
          objectIDs: [value.objectID],
          positions: [value.position],
          queryID: value.queryID,
        })).on('click button', () => {
          markAnswerReacted(value.id);
          renderCurrentAnswers();
        })[0]
      );
      container.appendChild(itemContainer);
    })
    const resultsContainer = $('#search-help .card-body').empty().append(container)[0];
    resultsContainer.scrollTop = 99999999; // Just scroll to the bottom
  }

  // Track what they're currently viewing so we can leave the help available as they navigate between pages
  var currentlyViewedAnswers = sessionStorage.getItem(sessionStorageKey);
  currentlyViewedAnswers = (currentlyViewedAnswers ? JSON.parse(currentlyViewedAnswers) : []);
  if (currentlyViewedAnswers.length > 0) {
    openHelp(false); // Have to open before rendering to get scroll right
    renderCurrentAnswers();
    document.getElementById('search-help-container').classList.add('left-open');
  }

  // Setup our autocomplete widget
  const loader = document.getElementById('help-searching');
  let enterFunction = null
  new Autocomplete({
    target: document.getElementById('target'),
    props: {
      value: '',
      id:'query',
      name:'query',
      editorAttrs:{placeholder:"Type your question & press return"},
      onClick: value => {
        currentlyViewedAnswers.push(value);
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(currentlyViewedAnswers));
        renderCurrentAnswers();
        $('#query').val('').focus();
        aa('clickedObjectIDsAfterSearch', {
          index: 'faq',
          eventName: 'FAQ Search Clicked',
          objectIDs: [value.objectID],
          positions: [value.position],
          queryID: value.queryID,
        });
      },
      resolver:searchTextStore=>{
        var mostRecentQuery = null;
        return derived(searchTextStore,($text,set)=>{
          enterFunction = $text ? ()=>{
            // console.log(`Searching on "${$text}"`);
            loader.classList.add('show');
            mostRecentQuery = $text;
            index.search($text, {
              clickAnalytics: true,
              // optionalFilters: ['categories:-ACA'],
            }).then(({ hits, query, queryID }) => {
              if (query == mostRecentQuery) {
                loader.classList.remove('show');
                var results = hits.filter(uniqByQuestionFn()).slice(0, 5).map(hitAsResultFn(queryID));
                set(results);
              }
            }, (error) => {
              loader.classList.remove('show');
              console.error('error:', error);
            });
          } : null;
          set([]);
        },[])
      },
    }
  });

  function uniqByQuestionFn() {
    // wish we had a recent version of lodash with _.uniqBy ...
    var seen = {};
    return hit => {
      if (seen[hit.question]) return false;
      return seen[hit.question] = true;
    };
  }

  function hitAsResultFn(queryID) {
    return (hit, index)=>({
      label: hit.question,
      value: {
        question: hit.question,
        answer: hit.answer.replace(/(https:\/\/www.track1099.com[^\s]*)/, '<a href="$1">$1</a>').replace(/\n/g, '<br/>'),
        objectID: hit.objectID,
        queryID: queryID,
        position: index + 1,
        id: Math.round(99999999*Math.random()) // just for local use
      },
    })
  }
})