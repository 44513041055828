import urlJsonResolver from "./urlJsonResolver"
import cachedFetchJsonFn from "./cachedFetchJsonFn"
import {readable,derived,reduced} from './store'

const GEOCODING_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
const CACHE_KEY = 'geoCenter'

const retrieveUrl = cachedFetchJsonFn()
let coordsCache
const coords = readable(null,set=>{
  // Don't get the coordinates again if we got them this session
  if(coordsCache) set(coordsCache);
  else if(coordsCache = sessionStorage.getItem(CACHE_KEY)) set(coordsCache);
  else {
    // get client IP coordinates
    retrieveUrl("https://ipapi.co/json/").then(({latitude,longitude})=>{
      if(!latitude||!longitude||!parseFloat(latitude)||!parseFloat(longitude)) return;
      sessionStorage.setItem(CACHE_KEY,coordsCache = `${longitude},${latitude}`);
      set(coordsCache)
    })
  }
  return ()=>{ set=(v)=>{} }; // last-unsubscribed function just disables set()
});

export default function(options){
  const mapboxAccessToken = options.mapbox;
  const resultsLimit = options.limit || 5;
  if(!mapboxAccessToken){
    console.log("mapboxGeocodeResolver requires option mapbox: ACCESS_TOKEN. No results will be provided.");
    return _=>readable([])
  }
  return function(searchTextStore){
    /*
     * significantText subscribes to searchTextStore and sets a new value for 
     * itself when the user has typed enough text that it's likely to make a 
     * big difference in mapBox results. Caveat: if the user stops typing for
     * otherwiseSetAfterMsec milliseconds, significantText sets the current value
     * even if it otherwise doesn't seem interesting.
     * 
     * Assuming the user typed without a pause of at least otherwiseSetAfterMsec,
     * these input strings would yield the following search strings:
     * 
     * "1234 S Houston St"
     * "1234 S ", "1234 S Hous", "1234 S Houston ", "1234 S Houston St"
     * 
     * "3 Longname Boulevard"
     * "3 Lon", "3 Longnam", "3 Longname ", "3 Longname Boul", "3 Longname Boulevar", "3 Longname Boulevard"
     * 
     * "230 E N St"
     * "230 E ", "230 E N ", "230 E N St"
     * 
     * The inclusion of trailing spaces in the search strings is intentional: if
     * I were a geo search engine, I would take advantage of the space at the
     * end of "230 E N " to rule out "230 E Newberry Ave" and the like.
     */
    const significantText = reduced(searchTextStore,(memo,text,set)=>{
      const hardMinimumChars = 5 // Don't set this too high. E.g., "18210", "423 W", or "2 E N" are enough for high-confidence answers in many places
      const otherwiseSetAfterMsec = 1_000
      const toSet = text && text.trimStart();
      // Set immediately if user has deleted the whole value
      if(!toSet) return set('');
      // Don't start searching until hardMinimumChars
      if(toSet.length < hardMinimumChars) return;
      // Set a value immediately if there's more text now than last time and...
      if(toSet.length > memo.length){
        const words = toSet.split(/\s+/);
        // ...the input has at least two complete words or at least 3 chars are in the second word...
        if(words.length > 2 || (words.length == 2 && words[1].length > 2)){
          const memoWords = memo.trimStart().split(/\s+/);
          // ...and the input now has more words or at least 4 more chars than previous.
          if(words.length > memoWords.length || toSet.length - 4 >= memo.length){
            return set(toSet);
          }
        }
      }
      // Set the current value if they don't type more for otherwiseSetAfterMsec milliseconds
      const debounced = setTimeout(()=>set(toSet),otherwiseSetAfterMsec);
      return ()=>clearTimeout(debounced);
    },'')

    const url = derived([significantText,coords],([$text,$coords])=>{
      if(!$text) return null;
      return `${GEOCODING_URL}${encodeURIComponent($text)}.json?types=address&limit=${resultsLimit}&country=US&access_token=${mapboxAccessToken}`+($coords ? `&proximity=${$coords}` : '')
    });

    return urlJsonResolver({transform:v=>{
      return (v.features||[]).map(({place_name,address:addressNumber,text:addressText,context,relevance})=>{
        const address = `${addressNumber||''} ${addressText||''}`.trim();
        const fullLocality = place_name.replace(addressNumber||'','').replace(addressText||'','').replace(/^\W+/,'')
        let foreign = false, city = '', state = '', zip = ''
        // context is enclosing geo regions at roughly-descending levels of granularity
        for(const place of context){
          // ids look like "postcode.10199349300049520"
          const type = /^\w+/.exec(place.id)[0]; // e.g., "postcode"
          // type corresponds to these types https://docs.mapbox.com/api/search/geocoding/#data-types
          if(type=='postcode') zip = place.text;
          else if(type=='place') city = place.text;
          else if(type=='region'){
            state = (/^US-(\w+)$/.exec(place.short_code||'')||[])[1];
            if(!state){
              state = place.text
              foreign = true
            }
          }
        }
        return {
          label: place_name,
          value: {
            label: place_name,
            address, foreign, fullLocality, city, state, zip, relevance
          }
        }
      })
    }})(url);
  }
}